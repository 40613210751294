document.addEventListener('DOMContentLoaded', function () {
  const tabs = document.querySelectorAll('.tab'); // タブ要素
  const pages = document.querySelectorAll('.page'); // 対応するページ要素
  const offset = 100;

  tabs.forEach(tab => {
    tab.addEventListener('click', function () {
      const text = this.textContent.trim().toLowerCase().replace(/\s+/g, ''); // テキスト整形

      // すべての .tab から active を削除
      tabs.forEach(el => el.classList.remove('active'));

      // クリックした .tab と同じ文言を含む .tab に active を付与
      tabs.forEach(el => {
        if (el.textContent.trim().toLowerCase().replace(/\s+/g, '').includes(text)) {
          el.classList.add('active');
        }
      });

      // すべての .page から show を削除し、対応するものに付与
      pages.forEach(page => {
        if (text.includes(page.id.toLowerCase())) {  // IDがテキストの一部と一致する場合
          page.classList.add('show');
          const pageTop = page.getBoundingClientRect().top + window.scrollY; // 要素の絶対位置
          window.scrollTo({
            top: pageTop - offset, // 上部に余白をつける
            behavior: 'smooth' // スムーズなスクロール
          });
        } else {
          page.classList.remove('show');
        }
      });
    });
  });
});
